import * as React from 'react';

function BookIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <path d="M101 111a10.07 10.07 0 007.069-2.928A10.073 10.073 0 00111 101c0-2.628-1.069-5.21-2.931-7.069A10.072 10.072 0 00101 91a10.072 10.072 0 00-7.069 2.931A10.064 10.064 0 0091 101c0 2.629 1.069 5.213 2.931 7.072A10.074 10.074 0 00101 111zm37.5 0H205c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10h-66.5c-5.525 0-10 4.478-10 10 0 5.525 4.475 10 10 10zM101 175h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10H101c-5.525 0-10 4.478-10 10 0 5.525 4.475 10 10 10zm0 64h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10H101c-5.525 0-10 4.478-10 10 0 5.525 4.475 10 10 10zm0 64h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10H101c-5.525 0-10 4.478-10 10 0 5.525 4.475 10 10 10zm114 54c0-5.522-4.475-10-10-10H101c-5.525 0-10 4.478-10 10 0 5.525 4.475 10 10 10h104c5.525 0 10-4.475 10-10zm82.003-256c0 5.525 4.479 10 10 10h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10h-104c-5.521 0-10 4.478-10 10zm114 54h-104c-5.521 0-10 4.478-10 10 0 5.525 4.479 10 10 10h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10zm0 64h-104c-5.521 0-10 4.478-10 10 0 5.525 4.479 10 10 10h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10zm0 64h-104c-5.521 0-10 4.478-10 10 0 5.525 4.479 10 10 10h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10zm0 64h-104c-5.521 0-10 4.478-10 10 0 5.525 4.479 10 10 10h104c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10z" />
      <path d="M502 94h-30V50c0-5.522-4.475-10-10-10H316.034C291.987 40 269.95 51.36 256 69.997 242.05 51.36 220.013 40 195.966 40H50c-5.525 0-10 4.478-10 10v44H10c-5.525 0-10 4.478-10 10v358c0 5.525 4.475 10 10 10h345.331c5.525 0 10-4.475 10-10 0-5.522-4.475-10-10-10H265.16a.725.725 0 00.034-.09.298.298 0 00.032-.056C273.81 431.29 293.754 418 316.036 418H462c5.525 0 10-4.475 10-10V114h20v338h-54.912c-5.526 0-10 4.478-10 10 0 5.525 4.474 10 10 10H502c5.525 0 10-4.475 10-10V104c0-5.522-4.475-10-10-10zM60 60h135.966C217.6 60 237.03 72.528 246 92.082v324.98C232.531 405.012 214.862 398 195.966 398H60V60zm-40 54h20v294c0 5.525 4.475 10 10 10h145.966c22.281 0 42.224 13.29 50.81 33.853a.24.24 0 00.03.056c.01.031.021.061.035.091H20V114zm432 284H316.034c-18.896 0-36.565 7.013-50.034 19.062V92.082C274.968 72.528 294.4 60 316.034 60H452v338z" />
      <path d="M396.5 452a10.074 10.074 0 00-7.069 2.931A10.064 10.064 0 00386.5 462c0 2.629 1.069 5.213 2.931 7.072A10.07 10.07 0 00396.5 472a10.07 10.07 0 007.069-2.928A10.073 10.073 0 00406.5 462c0-2.628-1.069-5.21-2.931-7.069A10.08 10.08 0 00396.5 452z" />
    </svg>
  );
}

export { BookIcon };
