import * as React from 'react';

function ChequeIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <path d="M42.084 325.34H245.2a7.524 7.524 0 007.525-7.525 7.525 7.525 0 00-7.525-7.526H42.084a7.525 7.525 0 00-7.525 7.526 7.525 7.525 0 007.525 7.525zm182.909 69.185c1.868 6.41 3.798 13.041 10.422 13.041 3.58 0 6.016 0 34.48-33.229 8.401 9.465 23.528 23.845 37.89 23.843.782 0 1.567-.043 2.345-.132 8.576-.98 15.493-6.728 21.598-11.799 11.177-9.288 13.182-9.184 17.603-4.636 16.52 16.991 36.665 11.223 45.006 5.8 3.486-2.267 4.5-6.944 2.234-10.429-2.264-3.485-6.902-4.489-10.386-2.224-1.433.898-14.336 8.424-26.064-3.638-8.298-8.536-16.534-8.324-21.985-6.64-5.986 1.847-11.09 6.09-16.028 10.191-4.705 3.91-9.57 7.951-13.689 8.422-8.372.963-24.319-14.365-32.458-25.018a7.52 7.52 0 00-5.789-2.962 7.546 7.546 0 00-5.936 2.654c-8.846 10.431-19.07 22.066-25.735 29.196-2.47-9.27-5.83-24.103-8.499-37.195a7.523 7.523 0 00-6.804-6 7.502 7.502 0 00-7.63 4.906l-18.466 50.187a7.526 7.526 0 0014.126 5.197l9.562-25.993c1.615 6.883 3.027 12.418 4.203 16.458zM42.084 291.488H245.2a7.525 7.525 0 007.525-7.526 7.524 7.524 0 00-7.525-7.525H42.084a7.526 7.526 0 000 15.051zm11.444-33.536h259.93c10.458 0 18.967-8.509 18.967-18.968V217.05c0-10.46-8.509-18.968-18.968-18.968H53.528c-10.46 0-18.968 8.508-18.968 18.968v21.935c0 10.458 8.508 18.968 18.968 18.968zm-3.919-40.904a3.922 3.922 0 013.918-3.918h259.93a3.921 3.921 0 013.916 3.918v21.935a3.921 3.921 0 01-3.917 3.917H53.528a3.922 3.922 0 01-3.918-3.917v-21.935zm411.728 173.405a7.524 7.524 0 00-7.525 7.525v17.83c0 4.4-3.578 7.977-7.977 7.977H222.381a7.526 7.526 0 100 15.051h223.454c12.698 0 23.027-10.33 23.027-23.028v-17.83a7.524 7.524 0 00-7.525-7.525z" />
      <path d="M506.715 128.875c-4.56-7.286-11.549-12.708-19.842-15.474l2.592-9.745c1.577-5.929.61-12.126-2.722-17.449-3.23-5.158-8.323-8.88-14.34-10.48l-6.5-1.73c-12.614-3.355-25.48 3.718-28.683 15.763l-21.218 79.776H23.017C10.327 169.537 0 179.867 0 192.566v223.242c0 12.698 10.326 23.028 23.017 23.028h165.247a7.525 7.525 0 100-15.05H23.017c-4.393 0-7.966-3.579-7.966-7.978V192.565c0-4.398 3.574-7.977 7.966-7.977H412l-5.34 20.085c-.006.018-.013.035-.018.053-.005.018-.007.036-.012.055l-15.432 58.023c-.004.014-.01.027-.013.041l-.009.042-6.824 25.657c-2.764 10.397 2.563 21.147 12.18 26.055l-5.395 20.28a7.526 7.526 0 0014.545 3.868l5.302-19.932c1.105.151 2.211.23 3.312.23 3.926 0 7.79-.95 11.27-2.815 5.536-2.964 9.453-7.86 11.03-13.79l6.831-25.686.005-.013.005-.022 10.375-39.01v126.155a7.524 7.524 0 007.525 7.525 7.524 7.524 0 007.525-7.525V192.566c0-2.46-.385-4.858-1.139-7.159l15.27-57.412c4.59 1.737 8.431 4.82 10.961 8.861 2.85 4.563 3.697 9.793 2.384 14.727l-12.399 46.618a7.527 7.527 0 007.278 9.462 7.528 7.528 0 007.267-5.593l12.4-46.618c2.392-9.002.914-18.437-4.168-26.577zM422.051 298.57c-.493 1.856-1.768 3.414-3.588 4.39-1.988 1.065-4.363 1.314-6.68.697l-6.5-1.729c-4.592-1.22-7.456-5.49-6.386-9.517l4.901-18.425 23.154 6.158-4.9 18.426zm8.77-32.97l-23.155-6.158 11.59-43.573 23.153 6.157L430.82 265.6zm15.456-58.12l-23.154-6.158L451.765 93.63c.875-3.29 4.113-5.416 7.773-5.416.818 0 8.996 2.058 8.996 2.058 2.317.616 4.253 2.01 5.452 3.923 1.096 1.751 1.428 3.737.934 5.593L446.277 207.48z" />
    </svg>
  );
}

export { ChequeIcon };
